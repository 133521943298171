import React, {useEffect} from 'react';
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonViewDidLeave} from '@ionic/react';
import './Tab3.css';
import {HandsDetection} from '../detections/hands';

let requestAnimationFrameEvent;
let handsDetenction: HandsDetection | null;

function start(): void {
    handsDetenction = new HandsDetection(
        document.querySelector('#webcamCanvasHand'),
        window.innerWidth,
        window.innerHeight,
    );
    handsDetenction.enablePlugins('browser');
    handsDetenction.start();
    requestAnimationFrameEvent = window.requestAnimationFrame(_loop);
}

async function _loop(timestamp: number) {
    requestAnimationFrameEvent = window.requestAnimationFrame(_loop);
}

const Tab3: React.FC = () => {
    useEffect(() => {
        start();
    }, []);

    useIonViewDidLeave(() => {
        console.log('leaving HandsDetection');
        if (handsDetenction) {
            handsDetenction.stop();
        }
    });

    return (
        <IonPage>
            {/*<IonHeader>
                <IonToolbar>
                    <IonTitle>Riconoscimento Segni</IonTitle>l
                </IonToolbar>
            </IonHeader>*/}
            <IonContent>
                <div id="webcamCanvasHand" className="uk-card uk-card-default uk-card-body webcamContainer"/>
            </IonContent>
        </IonPage>
    );
};

export default Tab3;
