import Handsfree from 'handsfree';

export class PoseDetection {
    #handsfree;

    constructor(
        webCamElem,
        width,
        height,
    ) {
        this.#handsfree = new Handsfree({
            showDebug: true,
            // hands: true,
            isClient: false,
            setup: {
                canvas: {
                    pose: {
                        // The canvas element to hold the skeletons and keypoints for hand model
                        $el: null,
                        width,
                        height,
                    },
                },
                video: {
                    // The video element to hold the webcam stream
                    $el: null,
                    width,
                    height,
                },
                wrap: {
                    // The element to put the video and canvas inside of
                    $el: null,
                    // The parent element
                    $parent: webCamElem,
                },
            },
            pose: {
                enabled: true,
                // Outputs only the top 25 pose landmarks if true,
                // otherwise shows all 33 full body pose landmarks
                // - Note: Setting this to true may result in better accuracy
                upperBodyOnly: false,
                // Helps reduce jitter over multiple frames if true
                smoothLandmarks: true,
                // Minimum confidence [0 - 1] for a person detection to be considered detected
                minDetectionConfidence: 0.5,
                // Minimum confidence [0 - 1] for the pose tracker to be considered detected
                // Higher values are more robust at the expense of higher latency
                minTrackingConfidence: 0.5,
            },
        });
    }

    _handsHandleEvent(data) {
        if (!data.hands) return;
        // console.log(data.hands.landmarksVisible);
    }

    start() {
        this.#handsfree.start();
        this.#handsfree.use('logger', this._handsHandleEvent);
    }

    enablePlugins(param) {
        this.#handsfree.enablePlugins(param);
    }

    stop() {
        this.#handsfree.stop();
    }
}
