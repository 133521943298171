import {State} from "./state";

export class Anim010State extends State {
    private _prevState: any;
    private _timeElapsed: any;
    private _input: any;
    constructor(parent: any) {
        super(parent);
    }

    get Name() {
        return 'Anim_010';
    }

    // @ts-ignore
    Enter(prevState: any): void {
        console.log('prevState', prevState, this._parent._proxy)
        this._prevState = prevState;
        const animations = this._parent._proxy._animations;
        const curAction = animations['Anim_010'].action;
        curAction.play();
    }

    Exit() {
        this._parent._proxy._animations['Anim_010'].action.stop();
    }

    // @ts-ignore
    Update(timeElapsed: any, input: any): any {
        this._timeElapsed = timeElapsed;
        this._input = input;
    }
}
