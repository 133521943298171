import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {
    IonApp, IonContent,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {handRight, person, body} from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Global CSS */
import './global.css';

/* Handsfree CSS */
import './theme/handsfree.css'

const App: React.FC = () => (
    <IonApp>
        <IonReactRouter>
            {
                window.location.pathname !== '/app' ?
                    <IonTabs>
                        <IonRouterOutlet>
                            <Route path="/tab1" component={Tab1} exact={true}/>
                            <Route path="/tab2" component={Tab2}/>
                            <Route path="/tab3" component={Tab3}/>
                            <Route path="/" render={() => <Redirect to="/tab1"/>} exact={true}/>
                        </IonRouterOutlet>
                        <IonTabBar slot="bottom">
                            <IonTabButton tab="tab1" href="/tab1">
                                <IonIcon icon={body}/>
                                <IonLabel>Frasi</IonLabel>
                            </IonTabButton>
                            <IonTabButton tab="tab2" href="/tab2">
                                <IonIcon icon={person}/>
                                <IonLabel>Riconoscimento Posa</IonLabel>
                            </IonTabButton>
                            <IonTabButton tab="tab3" href="/tab3">
                                <IonIcon icon={handRight}/>
                                <IonLabel>Riconoscimento Segni</IonLabel>
                            </IonTabButton>
                        </IonTabBar>
                    </IonTabs>
                    :
                    <IonRouterOutlet>
                        <Route path="/app" component={Tab1}/>
                    </IonRouterOutlet>
            }
        </IonReactRouter>
    </IonApp>
);

export default App;
