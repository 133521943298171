import React, {CSSProperties, useEffect} from 'react';
import {IonContent, IonIcon, IonLabel, IonPage, isPlatform} from '@ionic/react';
import './Tab1.css';
import {useSwipeable} from 'react-swipeable';
import {chevronBack, chevronForward} from 'ionicons/icons';
import {AvatarController} from '../avatar/controller/avatar.controller';

function badgeStyle(index: number): CSSProperties {
    return {
        left: `calc(${index}*(80vw + 20px))`,
        cursor: 'pointer',
    };
}

let avatar: AvatarController;

function createAvatar(): void {
    avatar = new AvatarController(window.innerWidth, window.innerHeight);
}

const Tab1: React.FC = () => {
    useEffect(() => {
        createAvatar();
    }, []);

    /*const frasi = [
        'La mamma prepara un bel dolce!',
        'Il signore legge tantissimi libri!',
        'Questa settimana andiamo in montagna a sciare!',
        'Oggi ci sarà una bellissima sorpresa!',
        'Domani andiamo al cinema.',
        'Fra una settimana guardiamo un bellissimo film.',
        'E\' arrivato Babbo Natale!',
        'Il mio nonno ha regalato 100€!',
        'La nonna stira i vestiti.',
        'Ieri sono andato a scuola.',
        'Presentazione ITA',
        'Presentazione ESP',
        'Presentazione FRA',
        'Presentazione INT',
    ];*/

    const frasi = {
        Anim_01: 'La mamma prepara un bel dolce!',
        Anim_02: 'Il signore legge tantissimi libri!',
        Anim_03: 'Questa settimana andiamo in montagna a sciare!',
        Anim_04: 'Oggi ci sarà una bellissima sorpresa!',
        Anim_05: 'Domani andiamo al cinema.',
        Anim_06: 'Fra una settimana guardiamo un bellissimo film.',
        Anim_07: 'E\' arrivato Babbo Natale!',
        Anim_08: 'Il mio nonno ha regalato 100€!',
        Anim_09: 'La nonna stira i vestiti.',
        Anim_010: 'Ieri sono andato a scuola.',
        Anim_esp: 'Presentazione Spagnolo',
        Anim_ita: 'Presentazione Italiano',
        Anim_fra: 'Presentazione Francese',
        Anim_int: 'Presentazione Internazionale',
        Anim_india: 'Presentazione India',
    }
    const frasiArrayValues = Object.values(frasi)

    const [fraseAttiva, setFraseAttiva] = React.useState(0);

    const containerStyle: CSSProperties = {
        width: `calc(${Object.keys(frasi).length}*(80vw + 20px))`,
        left: `calc(-${fraseAttiva}*(80vw + 20px) + 10vw - 10px)`,
    };

    const swipeSentence = useSwipeable({
        onSwipedLeft: () => {
            if (fraseAttiva < Object.keys(frasi).length - 1) {
                setFraseAttiva(fraseAttiva + 1);
                // avatar.loadState(`Anim_0${fraseAttiva + 2}`) // qui fraseAttiva si è ancora incrementata di +1, quindi dovendo aggiungere +1 di default (perché fraseAttiva va da 0 a 9 ma i file da 1 a 10), faccio +2
            }
        },
        onSwipedRight: () => {
            if (fraseAttiva > 0) {
                setFraseAttiva(fraseAttiva - 1);
                // avatar.loadState(`Anim_0${fraseAttiva}`) // qui fraseAttiva si è ancora decrementata di -1, quindi dovendo aggiungere +1 di default (perché fraseAttiva va da 0 a 9 ma i file da 1 a 10), faccio +1 -1 => 0, quindi fraseAttiva
            }
        },
        trackMouse: true,
    });

    return (
        <IonPage className={'content-style'}>
            <IonContent>
                <div className="uk-card uk-card-default uk-card-body webcamContainer"
                     style={{height: '100%', width: '100%', backgroundColor: 'var(--ion-color-primary)'}}>
                    <div id="avatarScene"/>
                </div>
                <div style={containerStyle} className={'container-style'} {...swipeSentence}>
                    {
                        Object.keys(frasi).map((key: string, index: number) =>
                            <div style={badgeStyle(index)} className={'badge-style'}
                                 onClick={
                                     event => {
                                         console.log(key, index);
                                         avatar.setState(key, event);

                                     }
                                 }>
                                <IonLabel style={{fontSize: '18pt', fontWeight: 'bold'}}>
                                    {frasiArrayValues[index]}
                                </IonLabel>
                            </div>,
                        )
                    }
                </div>
                <div className={'left-button'}
                     onClick={event => {
                         setFraseAttiva(fraseAttiva > 0 ? fraseAttiva - 1 : fraseAttiva);
                         // avatar.loadState(`Anim_0${(fraseAttiva > 0 ? fraseAttiva - 1 : fraseAttiva) + 1}`, event)
                     }}>
                    <IonIcon icon={chevronBack}
                             style={{
                                 color: (isPlatform('desktop') || isPlatform('tablet')) ? 'var(--ion-color-primary)' : 'transparent',
                                 fontSize: '100px',
                             }}/>
                </div>
                <div className={'right-button'}
                     onClick={event => {
                         setFraseAttiva(fraseAttiva < Object.keys(frasi).length - 1 ? fraseAttiva + 1 : fraseAttiva);
                         // avatar.loadState(`Anim_0${(fraseAttiva < frasi.length - 1 ? fraseAttiva + 1 : fraseAttiva) + 1}`, event)
                     }}>
                    <IonIcon icon={chevronForward}
                             style={{
                                 color: (isPlatform('desktop') || isPlatform('tablet')) ? 'var(--ion-color-primary)' : 'transparent',
                                 fontSize: '100px',
                             }}/>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Tab1;
