import React, {useEffect} from 'react';
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonViewDidLeave} from '@ionic/react';
import './Tab2.css';
import {PoseDetection} from '../detections/pose';

let requestAnimationFrameEvent;
let handsDetenction: PoseDetection | null;

function start(): void {
    handsDetenction = new PoseDetection(
        document.querySelector('#webcamCanvasPose'),
        window.innerWidth,
        window.innerHeight,
    );
    handsDetenction.enablePlugins('browser');
    handsDetenction.start();
    requestAnimationFrameEvent = window.requestAnimationFrame(_loop);
}

async function _loop(timestamp: number) {
    requestAnimationFrameEvent = window.requestAnimationFrame(_loop);
}

const Tab2: React.FC = () => {
    useEffect(() => {
        start();
    }, []);

    useIonViewDidLeave(() => {
        console.log('leaving PoseDetection');
        if (handsDetenction) {
            handsDetenction.stop();
        }
    });

    return (
        <IonPage>
            {/*<IonHeader>
                <IonToolbar>
                    <IonTitle class="ion-text-center">Riconoscimento Posa</IonTitle>l
                </IonToolbar>
            </IonHeader>*/}
            <IonContent>
                <div id="webcamCanvasPose" className="uk-card uk-card-default uk-card-body webcamContainer"/>
            </IonContent>
        </IonPage>
    );
};

export default Tab2;


/*
 import React, { useState } from 'react';
 import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonFab, IonFabButton, IonIcon, IonGrid, IonRow, IonCol, IonImg, IonActionSheet } from '@ionic/react';
 import { camera, trash, close } from 'ionicons/icons';
 import { usePhotoGallery, UserPhoto } from '../hooks/usePhotoGallery';

 const Tab2: React.FC = () => {
 const { deletePhoto, photos, takePhoto } = usePhotoGallery();
 const [photoToDelete, setPhotoToDelete] = useState<UserPhoto>();

 return (
 <IonPage>
 <IonHeader>
 <IonToolbar>
 <IonTitle>Photo Gallery</IonTitle>
 </IonToolbar>
 </IonHeader>
 <IonContent>
 <IonHeader collapse="condense">
 <IonToolbar>
 <IonTitle size="large">Photo Gallery</IonTitle>
 </IonToolbar>
 </IonHeader>
 <IonGrid>
 <IonRow>
 {photos.map((photo, index) => (
 <IonCol size="6" key={index}>
 <IonImg onClick={() => setPhotoToDelete(photo)} src={photo.webviewPath} />
 </IonCol>
 ))}
 </IonRow>
 </IonGrid>

 <IonFab vertical="bottom" horizontal="center" slot="fixed">
 <IonFabButton onClick={() => takePhoto()}>
 <IonIcon icon={camera}/>
 </IonFabButton>
 </IonFab>

 <IonActionSheet
 isOpen={!!photoToDelete}
 buttons={[{
 text: 'Delete',
 role: 'destructive',
 icon: trash,
 handler: () => {
 if (photoToDelete) {
 deletePhoto(photoToDelete);
 setPhotoToDelete(undefined);
 }
 }
 }, {
 text: 'Cancel',
 icon: close,
 role: 'cancel'
 }]}
 onDidDismiss={() => setPhotoToDelete(undefined)}
 />


 </IonContent>
 </IonPage>
 );
 };

 export default Tab2;
 */
