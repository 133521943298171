export class State {
    protected _parent: any;
    constructor(parent: any) {
        this._parent = parent;
    }

    Enter() {}
    Exit() {}
    Update() {}
}
